var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { VIDEO_URL } from "@/env";
var VideoPlayer = styled.video(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  object-position: center;\n  z-index: 1000;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  object-position: center;\n  z-index: 1000;\n"])));
var SkipButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  z-index: 1001;\n  padding: 10px 20px;\n  background: rgba(0, 0, 0, 0.5);\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n"], ["\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  z-index: 1001;\n  padding: 10px 20px;\n  background: rgba(0, 0, 0, 0.5);\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n"])));
var IntroVideo = function (_a) {
    var onEnd = _a.onEnd;
    var _b = useState(true), playing = _b[0], setPlaying = _b[1];
    var _c = useState(false), showSkip = _c[0], setShowSkip = _c[1];
    var videoRef = React.useRef(null);
    var handleSkip = function () {
        setPlaying(false);
        onEnd();
    };
    useEffect(function () {
        var timer = setTimeout(function () {
            setShowSkip(true); // Show skip button after 3 seconds
            // Enable keyboard skip after 3 seconds
            var handleKeyDown = function (event) {
                if (event.keyCode === 32) { // spacebar
                    setPlaying(false);
                    onEnd();
                }
            };
            window.addEventListener("keydown", handleKeyDown);
            return function () {
                window.removeEventListener("keydown", handleKeyDown);
            };
        }, 1500);
        return function () { return clearTimeout(timer); };
    }, []);
    useEffect(function () {
        if (videoRef.current) {
            playing ? videoRef.current.play() : videoRef.current.pause();
        }
    }, [playing]);
    return (React.createElement("div", null,
        React.createElement(VideoPlayer, { ref: videoRef, controls: true, onEnded: onEnd },
            React.createElement("source", { src: VIDEO_URL, type: "video/webm" }),
            "Your browser does not support the video tag."),
        showSkip && React.createElement(SkipButton, { onClick: handleSkip }, "Skip")));
};
export default IntroVideo;
var templateObject_1, templateObject_2;
