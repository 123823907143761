var _a;
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { http } from "wagmi";
import { mainnet, arbitrum, arbitrumSepolia } from "wagmi/chains";
import { ALCHEMY_KEY, NETWORK } from "@/env";
export var isMainnet = NETWORK === "mainnet";
export var config = getDefaultConfig({
    appName: "KOTE",
    projectId: "6da6829b50b465e05d25daa3d4c370f6",
    chains: [mainnet, arbitrum, arbitrumSepolia],
    transports: (_a = {},
        _a[mainnet.id] = http("https://mainnet.g.alchemy.com/v2/".concat(ALCHEMY_KEY)),
        _a[arbitrum.id] = http("https://arb-mainnet.g.alchemy.com/v2/".concat(ALCHEMY_KEY)),
        _a[arbitrumSepolia.id] = http("https://arb-sepolia.g.alchemy.com/v2/".concat(ALCHEMY_KEY)),
        _a),
});
var mainnetContracts = {
    bridge: "0x20349E4E216b35f7863ACB0eD474CD68F9da655a",
    gear: "0xdA52BB340e8F7b582970523C03451E4dDe5C6Bcd",
    fragments: "0x57811CCA25CfC9c176a5515f23ded33a4eAe380D",
};
var testnetContracts = {
    bridge: "0x40dfD822D62B88c0950f2c857b9D8D9BE5d79a18",
    gear: "0xBA2d18Ec141d01bf1e5fEC9Cbef75193EEdCd06D",
    fragments: "0xBe5bca7A065789aCabC829932D51fb67bbE195a0",
};
export var contracts = isMainnet ? mainnetContracts : testnetContracts;
